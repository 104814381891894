import gql from 'graphql-tag';

const query = {
  doctorMe: gql`
    query doctorMe {
      doctorMe {
        code
        success
        message
        is_from_admin
        doctor {
          id
          email
          timezone
          contact_no
          profile {
            doctor_id
            first_name
            last_name
            date_of_birth
            street
            city
            state
            postcode
            country
            profile_pic
            description
            provider_no
            prescriber_no
            registration_no
            onboarding_step
            hpi_no
            pbs_prescriber_no
            specialist_qualifications
          }
          phone_verification
          email_verification
          status
        }
      }
    }
  `,
  doctorSchedule: gql`
    query doctorSchedule($from: DateTime!, $to: DateTime!) {
      doctorSchedule(from: $from, to: $to) {
        code
        success
        message
        schedules {
          id
          end_at
          start_at
        }
      }
    }
  `,
  doctorMyPatients: gql`
    query doctorMyPatients($search: String, $paginator: Paginator!) {
      doctorMyPatients(search: $search, paginator: $paginator) {
        code
        message
        total
        patients {
          id
          email
          timezone
          contact_no
          updated_at
          profile {
            patient_id
            first_name
            last_name
            profile_pic
            date_of_birth
          }
          status
          email_verification
          phone_verification
          recent_consultation_id
          recent_reference_id
          consultation_status
          last_consultation_date
          last_consultation_date_end
        }
      }
    }
  `,
  consultationDoctorUpcoming: gql`
    query consultationDoctorUpcoming {
      consultationDoctorUpcoming {
        code
        success
        message
        consultation {
          id
          reference_id
          notes
          patient {
            id
            email
            contact_no
            profile {
              first_name
              last_name
              profile_pic
              patient_notes
              careteam_notes
              careteam_notes_last_edit_at
              careteam_notes_last_edit_by
            }
          }
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          enquiry
          start_at
          end_at
        }
      }
    }
  `,
  consultationDoctorPatientUpcoming: gql`
    query consultationDoctorPatientUpcoming($patientId: String!) {
      consultationDoctorPatientUpcoming(patient_id: $patientId) {
        code
        success
        message
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            contact_no
            profile {
              first_name
              last_name
              profile_pic
            }
          }
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          enquiry
          start_at
          end_at
        }
      }
    }
  `,
  doctorSummary: gql`
    query doctorSummary {
      doctorSummary {
        code
        message
        summary {
          month_appointment
          new_message
          total_consultation
          total_patient
          week_appointment
          active_patient
          inactive_patient
        }
      }
    }
  `,
  consultationDoctorsListByDate: gql`
    query consultationDoctorsListByDate($from: DateTime!, $to: DateTime!) {
      consultationDoctorsListByDate(from: $from, to: $to) {
        code
        message
        success
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            timezone
            contact_no
            status
            consultation_status
            last_consultation_date
            profile {
              patient_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          enquiry
        }
      }
    }
  `,
  doctorConsultations: gql`
    query doctorConsultations(
      $paginator: Paginator!
      $date_from: DateTime
      $date_to: DateTime
      $search: String
    ) {
      doctorConsultations(
        paginator: $paginator
        date_from: $date_from
        date_to: $date_to
        search: $search
      ) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              last_name
              profile_pic
              date_of_birth
              careteam_notes
              patient_notes
              relevant_notes
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            has_notes
            has_prescription
            has_video_call
          }
          status
          enquiry
        }
      }
    }
  `,
  products: gql`
    query products($filter: ProductFilter) {
      products(filter: $filter) {
        code
        success
        message
        products {
          id
          type
          brand
          product_name
          product_image
          stock
          format
          schedule
          price
          is_active
          is_formularly
          status
          availability
        }
      }
    }
  `,
  consultationDetails: gql`
    query consultationDetails($id: String!) {
      consultationDetails(id: $id) {
        code
        success
        message
        consultation {
          id
          reference_id
          notes
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              middle_name
              last_name
              pronoun
              gender
              date_of_birth
              street
              city
              state
              postcode
              country
              medicare_no
              profile_pic
              is_australian_resident
              is_over_18
              tried_conventional_treatment
              medical_symptoms
              medical_conditions
              conditions
              other_conditions
              how_long_suffered
              using_any_medicine
              have_any_allergy
              allergy_details
              relevant_notes
              about_polln
              currently_being_treated
              current_prescription
              taking_any_medication
              taking_any_medication_list
              achieve_polln
              addtl_info
              careteam_notes
              careteam_notes_last_edit_at
              careteam_notes_last_edit_by
              patient_notes
            }
            health_history {
              patient_id
              english_language
              primary_language
              video_comfortable
              heart_lung_condition
              mental_neuro_condition
              muscle_condition
              other_condition
              other_condition_list
              currently_taking_medications
              current_medications
              medicine_with_prescription
              medicine_with_prescription_details
              medicine_without_prescription
              medicine_without_prescription_details
              smoke_tobacco
              smoke_tobacco_frequency
              drink_alcohol
              drink_alcohol_frequency
              pregnant_breastfeeding
              psychiatric_disorder
              family_with_health_condition
              family_with_health_condition_details
              injury_surgery__hospitalization
              injury_surgery__hospitalization_details
              current_diet
              physical_activity_level
              stress_emotions
              status
            }
            background {
              patient_id
              cannabis_used
              have_medical_prescription
              cannabis_history
              has_allergy
              allergy
              allergy_severity
              has_surgery
              surgery
              surgery_date
              alcohol_used
              tobacco_used
              exescise_activity
              family_history
              other_family_history
              medical_history
              other_medical_history
              currently_being_treated
              current_clinic
              current_doctor
              current_date
              currently_taking_medication
              current_medication
              current_medication_dose
              current_work_status
              current_work
            }
            dva_no
            ihi_no
            ihi_record_status
            ihi_status
            ihi_verified
            pinned_alert
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            has_notes
            has_prescription
            has_video_call
          }
          status
          enquiry
          tga_approval
          tga_acceptance
          script_approval
          script_disabled
          documents {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
          can_finish
        }
      }
    }
  `,
  patientPrescription: gql`
    query patientPrescription($id: String!) {
      patientPrescription(id: $id) {
        code
        success
        message
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            no_of_units
            repeats
            dosage
            frequency
            route
            product {
              brand
              product_name
              quantity
              route
            }
            authority_no
            brand_substitition_not_allowed
            emergency_supply
            annotations
            unusual_dose
            unusual_quantity
            regulation_24
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
            frequency
            route
          }
          consultation_notes
          treatment_notes
          careteam_notes
          with_escript
          followup_period
          followup_value
          set_followup
          dispensing_interval
          dispensing_notes
          interval_reason
        }
      }
    }
  `,
  patientOtherConsultations: gql`
    query patientOtherConsultations($patient_id: String!, $consultation_id: String!) {
      patientOtherConsultations(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        success
        message
        consultations {
          consultation_ref_id
          consultation_status
          consultation_date
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            has_notes
            has_prescription
            has_video_call
          }
          prescription {
            id
            patient_id
            consultation_id
            products {
              prescription_id
              product_id
              product {
                id
                type
                brand
                product_name
                stock
                format
                schedule
                price
                is_active
              }
              no_of_units
              repeats
              dosage
              frequency
              route
            }
            custom_products {
              product_name
              product_details
              product_type
              no_of_units
              repeats
              dosage
              brand_name
              sku
              type
              format
              frequency
              route
            }
            consultation_notes
            treatment_notes
            careteam_notes
            with_escript
            consultation {
              id
              reference_id
              notes
              patient {
                id
                email
                timezone
                contact_no
                profile {
                  patient_id
                  first_name
                  middle_name
                  last_name
                  pronoun
                  gender
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  medicare_no
                  profile_pic
                  is_australian_resident
                  is_over_18
                  tried_conventional_treatment
                  medical_symptoms
                  medical_conditions
                  conditions
                  other_conditions
                  how_long_suffered
                  using_any_medicine
                  have_any_allergy
                  allergy_details
                  relevant_notes
                  onboarding_step
                  about_polln
                  currently_being_treated
                  current_prescription
                  taking_any_medication
                  taking_any_medication_list
                  achieve_polln
                  addtl_info
                  patient_notes
                }
                health_history {
                  patient_id
                  english_language
                  primary_language
                  video_comfortable
                  heart_lung_condition
                  mental_neuro_condition
                  muscle_condition
                  other_condition
                  other_condition_list
                  currently_taking_medications
                  current_medications
                  medicine_with_prescription
                  medicine_with_prescription_details
                  medicine_without_prescription
                  medicine_without_prescription_details
                  smoke_tobacco
                  smoke_tobacco_frequency
                  drink_alcohol
                  drink_alcohol_frequency
                  pregnant_breastfeeding
                  psychiatric_disorder
                  family_with_health_condition
                  family_with_health_condition_details
                  injury_surgery__hospitalization
                  injury_surgery__hospitalization_details
                  current_diet
                  physical_activity_level
                  stress_emotions
                  status
                }
                background {
                  patient_id
                  cannabis_used
                  have_medical_prescription
                  cannabis_history
                  has_allergy
                  allergy
                  allergy_severity
                  has_surgery
                  surgery
                  surgery_date
                  alcohol_used
                  tobacco_used
                  exescise_activity
                  family_history
                  other_family_history
                  medical_history
                  other_medical_history
                  currently_being_treated
                  current_clinic
                  current_doctor
                  current_date
                  currently_taking_medication
                  current_medication
                  current_medication_dose
                  current_work_status
                  current_work
                }
              }
              doctor {
                id
                email
                timezone
                contact_no
                profile {
                  doctor_id
                  first_name
                  last_name
                  date_of_birth
                  street
                  city
                  state
                  postcode
                  country
                  profile_pic
                  description
                  provider_no
                  prescriber_no
                  onboarding_step
                  hpi_no
                  pbs_prescriber_no
                  specialist_qualifications
                }
                phone_verification
                email_verification
                status
              }
              start_at
              end_at
              consultation_type {
                id
                name
                capitalize_name
                duration
                is_default
                has_notes
                has_prescription
                has_video_call
              }
              status
              enquiry
              tga_approval
              tga_acceptance
              script_approval
              script_disabled
              documents {
                filename
                file_url
                file_key
                uploaded_by
                file_type
              }
            }
          }
          script {
            filename
            file_url
            file_key
            uploaded_by
            file_type
          }
        }
      }
    }
  `,
  patientPreviousPrescription: gql`
    query patientPreviousPrescription($patient_id: String!, $consultation_id: String!) {
      patientPreviousPrescription(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        message
        success
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              stock
              format
              schedule
              price
              is_active
            }
            no_of_units
            repeats
            dosage
            frequency
            route
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
            frequency
            route
          }
          consultation_notes
          treatment_notes
          careteam_notes
          with_escript
          followup_period
          followup_value
          set_followup
          dispensing_interval
          consultation {
            id
            reference_id
            notes
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                allergy_details
                relevant_notes
                about_polln
                currently_being_treated
                current_prescription
                taking_any_medication
                taking_any_medication_list
                achieve_polln
                addtl_info
                patient_notes
              }
              health_history {
                patient_id
                english_language
                primary_language
                video_comfortable
                heart_lung_condition
                mental_neuro_condition
                muscle_condition
                other_condition
                other_condition_list
                currently_taking_medications
                current_medications
                medicine_with_prescription
                medicine_with_prescription_details
                medicine_without_prescription
                medicine_without_prescription_details
                smoke_tobacco
                smoke_tobacco_frequency
                drink_alcohol
                drink_alcohol_frequency
                pregnant_breastfeeding
                psychiatric_disorder
                family_with_health_condition
                family_with_health_condition_details
                injury_surgery__hospitalization
                injury_surgery__hospitalization_details
                current_diet
                physical_activity_level
                stress_emotions
                status
              }
              background {
                patient_id
                cannabis_used
                have_medical_prescription
                cannabis_history
                has_allergy
                allergy
                allergy_severity
                has_surgery
                surgery
                surgery_date
                alcohol_used
                tobacco_used
                exescise_activity
                family_history
                other_family_history
                medical_history
                other_medical_history
                currently_being_treated
                current_clinic
                current_doctor
                current_date
                currently_taking_medication
                current_medication
                current_medication_dose
                current_work_status
                current_work
              }
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
                hpi_no
                pbs_prescriber_no
                specialist_qualifications
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
              has_notes
              has_prescription
              has_video_call
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
        }
      }
    }
  `,
  doctorCalendar: gql`
    query doctorCalendar($from: DateTime!, $to: DateTime!) {
      doctorCalendar(from: $from, to: $to) {
        code
        success
        message
        schedules {
          id
          recurring_id
          start_at
          end_at
        }
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            timezone
            contact_no
            status
            consultation_status
            last_consultation_date
            profile {
              patient_id
              first_name
              last_name
              profile_pic
            }
          }
          start_at
          end_at
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          status
          enquiry
        }
      }
    }
  `,
  consultationDocuments: gql`
    query consultationDocuments($id: String!) {
      consultationDocuments(id: $id) {
        code
        success
        message
        documents {
          filename
          file_url
          file_key
          uploaded_by
          file_type
          upload_date
          consultation {
            id
            reference_id
            notes
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                allergy_details
                relevant_notes
                about_polln
                currently_being_treated
                current_prescription
                taking_any_medication
                taking_any_medication_list
                achieve_polln
                addtl_info
                patient_notes
              }
              health_history {
                patient_id
                english_language
                primary_language
                video_comfortable
                heart_lung_condition
                mental_neuro_condition
                muscle_condition
                other_condition
                other_condition_list
                currently_taking_medications
                current_medications
                medicine_with_prescription
                medicine_with_prescription_details
                medicine_without_prescription
                medicine_without_prescription_details
                smoke_tobacco
                smoke_tobacco_frequency
                drink_alcohol
                drink_alcohol_frequency
                pregnant_breastfeeding
                psychiatric_disorder
                family_with_health_condition
                family_with_health_condition_details
                injury_surgery__hospitalization
                injury_surgery__hospitalization_details
                current_diet
                physical_activity_level
                stress_emotions
                status
              }
              background {
                patient_id
                cannabis_used
                have_medical_prescription
                cannabis_history
                has_allergy
                allergy
                allergy_severity
                has_surgery
                surgery
                surgery_date
                alcohol_used
                tobacco_used
                exescise_activity
                family_history
                other_family_history
                medical_history
                other_medical_history
                currently_being_treated
                current_clinic
                current_doctor
                current_date
                currently_taking_medication
                current_medication
                current_medication_dose
                current_work_status
                current_work
              }
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
                hpi_no
                pbs_prescriber_no
                specialist_qualifications
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
              has_notes
              has_prescription
              has_video_call
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          visibility
        }
      }
    }
  `,
  consultationEscripts: gql`
    query consultationEscripts($id: String!) {
      consultationEscripts(id: $id) {
        code
        success
        message
        escripts {
          id
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              middle_name
              last_name
              pronoun
              gender
              date_of_birth
              street
              city
              state
              postcode
              country
              medicare_no
              profile_pic
              is_australian_resident
              is_over_18
              tried_conventional_treatment
              medical_symptoms
              medical_conditions
              conditions
              other_conditions
              how_long_suffered
              using_any_medicine
              have_any_allergy
              allergy_details
              relevant_notes
              about_polln
              currently_being_treated
              current_prescription
              taking_any_medication
              taking_any_medication_list
              achieve_polln
              addtl_info
              patient_notes
            }
            health_history {
              patient_id
              english_language
              primary_language
              video_comfortable
              heart_lung_condition
              mental_neuro_condition
              muscle_condition
              other_condition
              other_condition_list
              currently_taking_medications
              current_medications
              medicine_with_prescription
              medicine_with_prescription_details
              medicine_without_prescription
              medicine_without_prescription_details
              smoke_tobacco
              smoke_tobacco_frequency
              drink_alcohol
              drink_alcohol_frequency
              pregnant_breastfeeding
              psychiatric_disorder
              family_with_health_condition
              family_with_health_condition_details
              injury_surgery__hospitalization
              injury_surgery__hospitalization_details
              current_diet
              physical_activity_level
              stress_emotions
              status
            }
            background {
              patient_id
              cannabis_used
              have_medical_prescription
              cannabis_history
              has_allergy
              allergy
              allergy_severity
              has_surgery
              surgery
              surgery_date
              alcohol_used
              tobacco_used
              exescise_activity
              family_history
              other_family_history
              medical_history
              other_medical_history
              currently_being_treated
              current_clinic
              current_doctor
              current_date
              currently_taking_medication
              current_medication
              current_medication_dose
              current_work_status
              current_work
            }
          }
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          consultation_date
          product {
            id
            type
            brand
            product_name
            stock
            format
            schedule
            price
            is_active
          }
          reference_id
          description
          remaining
          total
          status
        }
      }
    }
  `,
  consultationPrescription: gql`
    query consultationPrescription($id: String!) {
      consultationPrescription(id: $id) {
        code
        success
        message
        prescription {
          id
          patient_id
          consultation_id
          products {
            prescription_id
            product_id
            product {
              id
              type
              brand
              product_name
              stock
              format
              schedule
              price
              is_active
            }
            no_of_units
            repeats
            dosage
            frequency
            route
          }
          custom_products {
            product_name
            product_details
            product_type
            no_of_units
            repeats
            dosage
            brand_name
            sku
            type
            format
            frequency
            route
          }
          consultation_notes
          treatment_notes
          careteam_notes
          with_escript
          interval_reason
          consultation {
            id
            reference_id
            notes
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                allergy_details
                relevant_notes
                about_polln
                currently_being_treated
                current_prescription
                taking_any_medication
                taking_any_medication_list
                achieve_polln
                addtl_info
                patient_notes
              }
              health_history {
                patient_id
                english_language
                primary_language
                video_comfortable
                heart_lung_condition
                mental_neuro_condition
                muscle_condition
                other_condition
                other_condition_list
                currently_taking_medications
                current_medications
                medicine_with_prescription
                medicine_with_prescription_details
                medicine_without_prescription
                medicine_without_prescription_details
                smoke_tobacco
                smoke_tobacco_frequency
                drink_alcohol
                drink_alcohol_frequency
                pregnant_breastfeeding
                psychiatric_disorder
                family_with_health_condition
                family_with_health_condition_details
                injury_surgery__hospitalization
                injury_surgery__hospitalization_details
                current_diet
                physical_activity_level
                stress_emotions
                status
              }
              background {
                patient_id
                cannabis_used
                have_medical_prescription
                cannabis_history
                has_allergy
                allergy
                allergy_severity
                has_surgery
                surgery
                surgery_date
                alcohol_used
                tobacco_used
                exescise_activity
                family_history
                other_family_history
                medical_history
                other_medical_history
                currently_being_treated
                current_clinic
                current_doctor
                current_date
                currently_taking_medication
                current_medication
                current_medication_dose
                current_work_status
                current_work
              }
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
                hpi_no
                pbs_prescriber_no
                specialist_qualifications
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
              has_notes
              has_prescription
              has_video_call
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          post_prescriptions {
            created_at
            notes
          }
        }
      }
    }
  `,
  patientDocuments: gql`
    query patientDocuments($id: String) {
      patientDocuments(id: $id) {
        code
        success
        message
        documents {
          filename
          file_url
          file_key
          uploaded_by
          file_type
          upload_date
          consultation {
            id
            reference_id
            notes
            patient {
              id
              email
              timezone
              contact_no
              profile {
                patient_id
                first_name
                middle_name
                last_name
                pronoun
                gender
                date_of_birth
                street
                city
                state
                postcode
                country
                medicare_no
                profile_pic
                is_australian_resident
                is_over_18
                tried_conventional_treatment
                medical_symptoms
                medical_conditions
                conditions
                other_conditions
                how_long_suffered
                using_any_medicine
                have_any_allergy
                allergy_details
                relevant_notes
                about_polln
                currently_being_treated
                current_prescription
                taking_any_medication
                taking_any_medication_list
                achieve_polln
                addtl_info
                patient_notes
              }
              health_history {
                patient_id
                english_language
                primary_language
                video_comfortable
                heart_lung_condition
                mental_neuro_condition
                muscle_condition
                other_condition
                other_condition_list
                currently_taking_medications
                current_medications
                medicine_with_prescription
                medicine_with_prescription_details
                medicine_without_prescription
                medicine_without_prescription_details
                smoke_tobacco
                smoke_tobacco_frequency
                drink_alcohol
                drink_alcohol_frequency
                pregnant_breastfeeding
                psychiatric_disorder
                family_with_health_condition
                family_with_health_condition_details
                injury_surgery__hospitalization
                injury_surgery__hospitalization_details
                current_diet
                physical_activity_level
                stress_emotions
                status
              }
              background {
                patient_id
                cannabis_used
                have_medical_prescription
                cannabis_history
                has_allergy
                allergy
                allergy_severity
                has_surgery
                surgery
                surgery_date
                alcohol_used
                tobacco_used
                exescise_activity
                family_history
                other_family_history
                medical_history
                other_medical_history
                currently_being_treated
                current_clinic
                current_doctor
                current_date
                currently_taking_medication
                current_medication
                current_medication_dose
                current_work_status
                current_work
              }
            }
            doctor {
              id
              email
              timezone
              contact_no
              profile {
                doctor_id
                first_name
                last_name
                date_of_birth
                street
                city
                state
                postcode
                country
                profile_pic
                description
                provider_no
                prescriber_no
                onboarding_step
                hpi_no
                pbs_prescriber_no
                specialist_qualifications
              }
              phone_verification
              email_verification
              status
            }
            start_at
            end_at
            consultation_type {
              id
              name
              capitalize_name
              duration
              is_default
              has_notes
              has_prescription
              has_video_call
            }
            status
            enquiry
            tga_approval
            tga_acceptance
            script_approval
            script_disabled
            documents {
              filename
              file_url
              file_key
              uploaded_by
              file_type
            }
          }
          visibility
        }
      }
    }
  `,
  doctorUpcomingConsultations: gql`
    query doctorUpcomingConsultations($count: Int!) {
      doctorUpcomingConsultations(count: $count) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            contact_no
            profile {
              first_name
              last_name
              profile_pic
              careteam_notes
              careteam_notes_last_edit_at
              careteam_notes_last_edit_by
              patient_notes
            }
          }
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          enquiry
          start_at
          end_at
          status
        }
      }
    }
  `,
  doctorRecentConsultations: gql`
    query doctorRecentConsultations($count: Int!) {
      doctorRecentConsultations(count: $count) {
        code
        success
        message
        count
        consultations {
          id
          reference_id
          notes
          patient {
            id
            email
            contact_no
            profile {
              first_name
              last_name
              profile_pic
            }
          }
          consultation_type {
            id
            name
            price
            duration
            capitalize_name
            has_notes
            has_prescription
            has_video_call
          }
          enquiry
          start_at
          end_at
          status
        }
      }
    }
  `,
  doctorConsultationSummary: gql`
    query doctorConsultationSummary {
      doctorConsultationSummary {
        code
        message
        success
        data {
          available
          booked
          percentage
        }
      }
    }
  `,
  doctorEscripts: gql`
    query doctorEscripts($count: Int!, $status: [EScriptStatus], $is_expiring: Boolean) {
      doctorEscripts(count: $count, status: $status, is_expiring: $is_expiring) {
        code
        message
        success
        escripts {
          id
          patient {
            id
            email
            timezone
            contact_no
            profile {
              patient_id
              first_name
              middle_name
              last_name
              pronoun
              gender
              date_of_birth
              street
              city
              state
              postcode
              country
              medicare_no
              profile_pic
              is_australian_resident
              is_over_18
              tried_conventional_treatment
              medical_symptoms
              medical_conditions
              conditions
              other_conditions
              how_long_suffered
              using_any_medicine
              have_any_allergy
              allergy_details
              relevant_notes
              about_polln
              currently_being_treated
              current_prescription
              taking_any_medication
              taking_any_medication_list
              achieve_polln
              addtl_info
              patient_notes
            }
            health_history {
              patient_id
              english_language
              primary_language
              video_comfortable
              heart_lung_condition
              mental_neuro_condition
              muscle_condition
              other_condition
              other_condition_list
              currently_taking_medications
              current_medications
              medicine_with_prescription
              medicine_with_prescription_details
              medicine_without_prescription
              medicine_without_prescription_details
              smoke_tobacco
              smoke_tobacco_frequency
              drink_alcohol
              drink_alcohol_frequency
              pregnant_breastfeeding
              psychiatric_disorder
              family_with_health_condition
              family_with_health_condition_details
              injury_surgery__hospitalization
              injury_surgery__hospitalization_details
              current_diet
              physical_activity_level
              stress_emotions
              status
            }
            background {
              patient_id
              cannabis_used
              have_medical_prescription
              cannabis_history
              has_allergy
              allergy
              allergy_severity
              has_surgery
              surgery
              surgery_date
              alcohol_used
              tobacco_used
              exescise_activity
              family_history
              other_family_history
              medical_history
              other_medical_history
              currently_being_treated
              current_clinic
              current_doctor
              current_date
              currently_taking_medication
              current_medication
              current_medication_dose
              current_work_status
              current_work
            }
          }
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          consultation_date
          product {
            id
            type
            brand
            product_name
            stock
            format
            schedule
            price
            is_active
          }
          reference_id
          description
          remaining
          total
          status
          tokens {
            id
            status
          }
        }
      }
    }
  `,
  patientProfile: gql`
    query patientProfile($id: String!) {
      patientProfile(id: $id) {
        code
        message
        success
        is_from_admin
        patient {
          id
          email
          timezone
          contact_no
          profile {
            patient_id
            first_name
            middle_name
            last_name
            pronoun
            gender
            date_of_birth
            street
            city
            state
            postcode
            country
            medicare_no
            medicare_valid_to
            profile_pic
            is_australian_resident
            is_over_18
            tried_conventional_treatment
            medical_symptoms
            medical_conditions
            conditions
            other_conditions
            how_long_suffered
            using_any_medicine
            have_any_allergy
            allergy_details
            relevant_notes
            about_polln
            currently_being_treated
            current_prescription
            taking_any_medication
            taking_any_medication_list
            achieve_polln
            addtl_info
            patient_notes
            careteam_notes
          }
          health_history {
            patient_id
            english_language
            primary_language
            video_comfortable
            heart_lung_condition
            mental_neuro_condition
            muscle_condition
            other_condition
            other_condition_list
            currently_taking_medications
            current_medications
            medicine_with_prescription
            medicine_with_prescription_details
            medicine_without_prescription
            medicine_without_prescription_details
            smoke_tobacco
            smoke_tobacco_frequency
            drink_alcohol
            drink_alcohol_frequency
            pregnant_breastfeeding
            psychiatric_disorder
            family_with_health_condition
            family_with_health_condition_details
            injury_surgery__hospitalization
            injury_surgery__hospitalization_details
            current_diet
            physical_activity_level
            stress_emotions
            status
          }
          background {
            patient_id
            cannabis_used
            have_medical_prescription
            cannabis_history
            has_allergy
            allergy
            allergy_severity
            has_surgery
            surgery
            surgery_date
            alcohol_used
            tobacco_used
            exescise_activity
            family_history
            other_family_history
            medical_history
            other_medical_history
            currently_being_treated
            current_clinic
            current_doctor
            current_date
            currently_taking_medication
            current_medication
            current_medication_dose
            current_work_status
            current_work
          }
          latest_prescription {
            careteam_notes
            consultation_id
            consultation_notes
            dispensing_interval
            dispensing_notes
            followup_period
            followup_value
            id
            patient_id
            set_followup
            treatment_notes
            with_escript
          }
          status
          dva_no
          ihi_no
          ihi_record_status
          ihi_status
          ihi_verified
          pinned_alert
        }
      }
    }
  `,
  patientConsultations: gql`
    query patientConsultations(
      $id: String
      $paginator: Paginator
      $date_from: DateTime
      $date_to: DateTime
      $status: [ConsultationStatus]
    ) {
      patientConsultations(
        id: $id
        paginator: $paginator
        date_from: $date_from
        date_to: $date_to
        status: $status
      ) {
        code
        message
        success
        count
        consultations {
          id
          reference_id
          notes
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          start_at
          end_at
          consultation_type {
            id
            name
            capitalize_name
            duration
            is_default
            has_notes
            has_prescription
            has_video_call
          }
          status
          enquiry
          tga_approval
          tga_acceptance
          script_approval
          script_disabled
          can_finish
        }
      }
    }
  `,
  patientEscripts: gql`
    query patientEscripts($id: String, $count: Int, $is_history: Boolean) {
      patientEscripts(id: $id, count: $count, is_history: $is_history) {
        code
        message
        success
        escripts {
          id
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          consultation_date
          product {
            id
            type
            brand
            product_name
            stock
            format
            schedule
            price
            is_active
          }
          tokens {
            id
            doctor_name
            expiry
            source_ref
            source_time
            status
            order_no
            ready_at
            prescribed_at
            paid_at
          }
          reference_id
          description
          remaining
          total
          status
          expiry
          created_at
          erx_type
        }
      }
    }
  `,
  patientDispensingLimits: gql`
    query patientDispensingLimits($id: String!) {
      patientDispensingLimits(id: $id) {
        code
        message
        success
        data {
          dispensing_limit
          icon
          id
          name
          unit
        }
      }
    }
  `,
  consultationHistoryNotes: gql`
    query consultationPatientHistory($patient_id: String, $consultation_id: String) {
      consultationPatientHistory(patient_id: $patient_id, consultation_id: $consultation_id) {
        code
        message
        success
        consultations {
          reference_id
          start_at
          end_at
          prescription {
            careteam_notes
            consultation_notes
            treatment_notes
          }
        }
      }
    }
  `,
  productTypes: gql`
    query productTypes {
      productTypes {
        code
        message
        success
        product_types {
          dispensing_limit
          icon
          id
          name
          unit
        }
      }
    }
  `,
  consultationTypes: gql`
    query consultationTypes($doctorId: String) {
      consultationTypes(doctorId: $doctorId) {
        code
        success
        message
        consultation_types {
          id
          name
          capitalize_name
          duration
          price
          is_default
          is_preselected
          description
          has_notes
          has_prescription
          has_video_call
        }
      }
    }
  `,
  productDetails: gql`
    query productDetails($id: String!) {
      productDetails(id: $id) {
        code
        message
        success
        product {
          id
          product_name
          product_image
          schedule
          description
          category
          stock
          price
          format
          is_active
          type
          size
          size_unit
          weight
          thc
          cbd
          is_thc
          is_cbd
          is_high_cbd
          is_high_thc
          product_type {
            dispensing_limit
            unit
            icon
            name
          }
          brand
          updated_at
          cultivar
          strength
          is_formularly
          administration
          estimate_dispatch
          status
        }
      }
    }
  `,
  doctorNotifications: gql`
    query doctorNotifications {
      doctorNotifications {
        code
        message
        success
        notifications {
          content
          id
          is_read
          link
          title
          created_at
        }
      }
    }
  `,
  patientDispensingLimitsHistory: gql`
    query patientDispensingLimitsHistory($id: String!) {
      patientDispensingLimitsHistory(id: $id) {
        code
        message
        success
        data {
          clinical_notes
          created_at
          doctor {
            id
            email
            timezone
            contact_no
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            phone_verification
            email_verification
            status
          }
          new_dispensing_limit
          old_dispensing_limit
          product_type {
            dispensing_limit
            icon
            id
            name
            unit
          }
        }
      }
    }
  `,
  patientDispensingIntervalHistory: gql`
    query patientDispensingIntervalHistory($id: String!) {
      patientDispensingIntervalHistory(id: $id) {
        code
        data {
          created_at
          dispensing_interval
          interval_reason
          updated_by
        }
        message
        success
      }
    }
  `,
  consultationZoomToken: gql`
    query consultationZoomToken($consultationZoomTokenId: String!) {
      consultationZoomToken(id: $consultationZoomTokenId) {
        code
        message
        success
        token
      }
    }
  `,
  doctorGetPatientNotes: gql`
    query doctorGetPatientNotes($patientId: String!) {
      doctorGetPatientNotes(patientId: $patientId) {
        code
        message
        success
        notes {
          notes
          created_at
          doctor {
            contact_no
            email
            phone_verification
            profile {
              doctor_id
              first_name
              last_name
              date_of_birth
              street
              city
              state
              postcode
              country
              profile_pic
              description
              provider_no
              prescriber_no
              registration_no
              onboarding_step
              hpi_no
              pbs_prescriber_no
              specialist_qualifications
            }
            id
            status
            timezone
          }
        }
      }
    }
  `,
  doctorGetPatientPinnedNotesHistory: gql`
    query doctorGetPatientPinnedNotesHistory($patientId: String!) {
      doctorGetPatientPinnedNotesHistory(patientId: $patientId) {
        code
        message
        success
        notes {
          note_by
          notes
          created_at
        }
      }
    }
  `,
  doctorGetPatientCareteamNotes: gql`
    query doctorGetPatientCareteamNotes($patientId: String!) {
      doctorGetPatientCareteamNotes(patientId: $patientId) {
        code
        message
        success
        notes {
          created_at
          notes
          note_by
        }
      }
    }
  `
};

export default query;
